<template>
  <div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['message']"
    />
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Mobile/Notifications/DatatablesWrapper.vue";
export default {
  components: {
    DatatablesWrapper,
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "message",
      },
      { text: "Status", value: "seen" },
      { text: "Timestamp", value: "timestamp" },
      { text: "Actions", value: "actions", sortable: false },
      //value = nombre del atributo dentro del array de items
    ],
    data: {},
    loading: false,
  }),

  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("notification/getNotificationsV1", options)
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>

<style>
</style>