<template>
  <div>
    <tgl-card-header-mobile><h1>Notificaciones</h1></tgl-card-header-mobile>
    <tgl-card-content-mobile>
      <notifications-table></notifications-table>
    </tgl-card-content-mobile>
  </div>
</template>

<script>
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
import NotificationsTable from "@/components/Mobile/Notifications/NotificationsTable.vue";
export default {
  name: "mobile-devices-table-page",
  components: { TglCardHeaderMobile, TglCardContentMobile, NotificationsTable },
};
</script>

<style lang="sass">
</style>