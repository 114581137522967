<template>
  <div>
    <div class="row ml-3">
      <datatables-filter
        v-model="filter"
        :parameters="parameters"
        :parameter="parameter"
        :filterType="filterType"
      />
      <v-tooltip bottom v-if="selected.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            size="30"
            class="ml-10 data-table-icon"
            @click="markAsSeenMultiple"
            >mdi-email-open-multiple-outline</v-icon
          >
        </template>
        <span>Marcar notificaciones seleccionadas como leidas</span>
      </v-tooltip>
      <v-tooltip bottom v-if="selected.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            size="30"
            class="ml-5 data-table-icon"
            @click="markAsNotSeenMultiple"
            >mdi-email-multiple-outline</v-icon
          >
        </template>
        <span>Marcar notificaciones seleccionadas como no leídas</span>
      </v-tooltip>
      <v-tooltip bottom v-if="selected.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            size="30"
            class="ml-5 data-table-icon"
            @click="deleteNotificationMultiple"
            >mdi-delete-outline</v-icon
          >
        </template>
        <span>Borrar notificaciones seleccionadas</span>
      </v-tooltip>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      class="elevation-0"
      single-expand
      show-expand
      :item-class="rowStyle"
      show-select
      v-model="selected"
    >
      <template v-slot:item.seen="{ item }">
        <v-icon v-if="item.seen">mdi-email-open-outline</v-icon>
        <v-icon v-else>mdi-email-outline</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="row justify-space-around">
            <div class="col-8">
              <span
                >La notificación procede del <b>Dispositivo:</b>
                {{ item.device }} y del <b>Slot:</b> {{ item.slot }}</span
              >
            </div>
            <div class="col-2">
              <div class="row justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-3 mr-3 data-table-icon"
                      @click="markAsSeen(item)"
                      >mdi-email-open-outline</v-icon
                    >
                  </template>
                  <span>Marcar notificación como leída</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="data-table-icon mr-3"
                      @click="markAsNotSeen(item)"
                      >mdi-email-outline</v-icon
                    >
                  </template>
                  <span>Marcar notificación como no leída</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="data-table-icon"
                      @click="deleteNotification(item)"
                      >mdi-delete-outline</v-icon
                    >
                  </template>
                  <span>Eliminar notificación</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="ml-3 mr-3 data-table-icon" @click="toNotification(item)"
          >mdi-eye-outline</v-icon
        >
        <v-icon class="mr-3 data-table-icon" @click="markAsSeen(item)"
          >mdi-email-open-outline</v-icon
        >
        <v-icon class="data-table-icon mr-3" @click="markAsNotSeen(item)"
          >mdi-email-outline</v-icon
        >
        <v-icon class="data-table-icon" @click="deleteNotification(item)"
          >mdi-delete-outline</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatatablesFilter from "@/components/Mobile/Notifications/DatatablesFilter.vue";

export default {
  components: { DatatablesFilter },
  props: {
    defaultOptions: {
      type: Object,
      default: () => ({
        multiSort: true,
      }),
    },
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        data: [],
        total: 0,
      }),
    },
    parameters: {
      type: Array, //mismos headers que se le pasan al datatables, sólo se usa para simpleWithSelector o para multiple
    },
    filterType: {
      type: String,
      default: "simple", // ['simple', 'simpleWithSelector', 'multiple'] Por ahora sólo se ha desarrollado para 'simple'
    },
    parameter: {
      type: String, // se puede utilizar cuando se usa el filterType simple, es para filtrar usando un sólo parámetro y no todos los posibles
    },
  },
  data: () => ({
    options: {},
    filter: [],
    selected: [],
  }),
  beforeMount() {
    Object.keys(this.defaultOptions).forEach(
      (key) => (this.options[key] = this.defaultOptions[key])
    );
  },
  watch: {
    options: {
      handler() {
        this.emitOptions();
      },
      deep: true,
    },
    filter() {
      this.emitOptions();
    },
  },
  computed: {
    items() {
      return this.data?.data || []; // si this.data existe retorno el valor de this.data.data. En el caso contrario retorno un array vacío
    },
    total() {
      return this.data?.total || 0; // si this.data existe retorno el valor de this.data.total. En el caso contrario retorno 0
    },
  },
  methods: {
    emitOptions() {
      const order = this.options.sortBy.map((item, index) => [
        item,
        this.options.sortDesc[index] ? "DESC" : "ASC",
      ]);
      const filters =
        this.filter.length > 0 ? JSON.stringify(this.filter) : this.filter;
      const newOptions = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        order: JSON.stringify(order),
        filters,
      };

      this.$emit("optionsChanged", newOptions);
      console.log("Nueva configuración para datatables:", newOptions);
    },
    toNotification(item) {
      this.$router.push({
        name: "mobileNotificationDetails",
        params: { notification: item.id },
      });
    },

    markAsSeen(item) {
      let notifications = { notifications: [item.id] };

      this.$store
        .dispatch("notification/markAsSeenNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificación fue marcada como leída");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
    markAsNotSeen(item) {
      let notifications = { notifications: [item.id] };
      this.$store
        .dispatch("notification/markAsNotSeenNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificación fue marcada como no leída");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
    deleteNotification(item) {
      let notifications = { notifications: [item.id] };

      this.$store
        .dispatch("notification/deleteNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificiacón fue borrada");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
    markAsSeenMultiple() {
      let notifications = { notifications: [] };
      this.selected.forEach((notification) => {
        notifications.notifications.push(notification.id);
      });
      this.$store
        .dispatch("notification/markAsSeenNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificación fue marcada como leída");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
    markAsNotSeenMultiple() {
      let notifications = { notifications: [] };
      this.selected.forEach((notification) => {
        notifications.notifications.push(notification.id);
      });
      this.$store
        .dispatch("notification/markAsNotSeenNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificación fue marcada como no leída");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
    deleteNotificationMultiple() {
      let notifications = { notifications: [] };
      this.selected.forEach((notification) => {
        notifications.notifications.push(notification.id);
      });
      this.$store
        .dispatch("notification/deleteNotificationsV1", notifications)
        .then((data) => {
          if (data) {
            this.$toast.success("La notificiacón fue borrada");
            this.$emit("updateTable", this.options);
          }
        })
        .catch((err) => {
          if (err) this.$toast.error("Algo salió mal");
        });
    },
  },
};
</script>
<style lang="sass">
.data-table-row-pointer-seen
  cursor: pointer
  background-color: #f5f5f5
.data-table-row-pointer-unseen
  cursor: pointer
  background-color: white
.data-table-icon
  cursor: pointer
</style>